<template lang="pug">
el-select.education(
  :disabled="disabled"
  clearable
  v-model="education", placeholder="請選擇最高學歷")
  el-option(v-for="e in educationList"
    :key="e.value"
    :value="e.value"
    :label="e.text"
)
</template>

<script>
export default {
  name: 'education-select',
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      if (this.education !== this.value) {
        this.education = this.value;
      }
    },
    education() {
      if (this.education !== this.value) {
        this.$emit('input', this.education);
      }
    },
  },
  data() {
    return {
      education: '',
      educationList: [
        { value: 'doctor', text: this.$msg('appointment.education.doctor') },
        { value: 'master', text: this.$msg('appointment.education.master') },
        { value: 'bachelor', text: this.$msg('appointment.education.bachelor') },
        { value: 'associate', text: this.$msg('appointment.education.associate') },
        { value: 'seniorhigh', text: this.$msg('appointment.education.seniorhigh') },
        { value: 'juniorhigh', text: this.$msg('appointment.education.juniorhigh') },
        { value: 'elementary', text: this.$msg('appointment.education.elementary') },
      ],
    };
  },
  mounted() {
    this.education = this.value;
  },
};
</script>

<style lang="scss" scoped>

</style>
