<template lang="pug">
.appointment-block.flex.align-stretch.justify-stretch.clickable(
  @click="$emit('click')"
  :data-start-at="appointment.start_at"
  :data-start="startTime"
  :data-index="index"
  :length="length"
  :style="style")
  .block.flex-1.flex.column.gap-4(:class="{'unpaid': showUnpaid}")
    .header.flex.align-center.gap-10
      .name.flex-1.flex.align-center.justify-start.gap-6
        .pro
          span(
            v-if="appointment.professional.given_name.length === 1"
            ) {{ appointment.professional.family_name }}
          span {{ appointment.professional.given_name }}
          span &nbsp;案
      el-tooltip(v-if="appointment.note" :content="appointment.note", placement="bottom")
        i.el-icon-chat-line-round
      .tag-num.flex.align-center.justify-center {{ appointment.tags.length }}
      i.el-icon-office-building(v-if="appointment.location_type === 'inhouse'")
      i.el-icon-phone-outline(v-else)
    .tags.flex.wrap.align-center.gap-6(v-if="multiLine")
      .name.flex-1.flex.align-center.justify-start
        .clickable(
          @click.stop="showCase"
          ) {{ appointment.user.family_name }}{{ appointment.user.given_name }}
        template(v-if="appointment.type !== 'parent'")
          template(v-for="(p, idx) in appointment.partners")
            span(:key="`dot-${idx}`") 、
            .name.clickable(
              :key="p.id"
              @click="showCase(p)"
              ) {{ p.family_name }}{{ p.given_name }}
        template(v-else)
          template(v-for="(p, idx) in appointment.partners")
            .name.clickable.ml-4.flex.align-center.gap-4(
              :key="p.id"
              @click="showCase(p)"
              )
              span (
              template(v-if="appointment.subtype === 'statutoryagents'")
                span 法代
                span :
              template(v-else-if="appointment.subtype === 'children'")
                span 子女
                span :
              span {{ p.family_name }}{{ p.given_name }}
              span )
  person-viewer(
    ref="personViewer"
    @showDetail="showDetail"
    :append-to-body="true"
  )
</template>

<script>
import { updateAppointmentTags } from '@/api/reserve';
import PersonViewer from '@/components/drawers/PersonViewer.vue';

export default {
  components: {
    PersonViewer,
  },
  props: {
    rooms: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    appointment: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      style: {},
      multiLine: false,
      startAt: 0,
      startTime: '',
      length: 0,
      index: 0,
    };
  },
  computed: {
    showUnpaid() {
      return this.appointment.payment === 'notpaid';
    },
  },
  methods: {
    showCase() {
      this.$refs.personViewer.$emit('show', {
        type: 'id',
        data: this.appointment.user.id,
      });
    },
    computeStyle() {
      const start = new Date(this.appointment.start_at * 1000);
      const length = (this.appointment.end_at - this.appointment.start_at) / 1800;
      let height = 60 * length;
      if (height < 60) {
        height = 60;
      }
      this.multiLine = height >= 48;
      const hour = start.getHours();
      const minute = start.getMinutes();
      const index = this.rooms.findIndex((r) => r.id === this.appointment.room.id);
      this.index = index;
      this.startTime = `${hour}:${minute}`;
      this.length = length;

      return {
        height: `${height}px`,
        top: `${60 + 60 * 2 * hour + (minute >= 30 ? 60 : 0)}px`,
        left: `${80 + 160 * index}px`,
      };
    },
    async updateBranchTags(branch) {
      return this.$execWithLoading(async () => {
        await updateAppointmentTags(branch.id, branch.tags);
        this.$showSuccess('已更新標記');
      }, () => {
        this.$showError('更新標記失敗');
      });
    },
    showDetail(user) {
      this.$root.$emit('show-user', user);
    },
  },
  mounted() {
    this.style = this.computeStyle();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.appointment-block {
  position: absolute;
  box-sizing: border-box;
  width: 160px;
  padding: 2px;
  .block {
    border-radius: 4px;
    padding: 5px;
    background: $primary-10-color;
    color: $primary-70-color;
    border: 1px solid $primary-70-color;
    &.unpaid {
      background: $danger-10-color;
      border: 1px solid $danger-70-color;
      i {
        color: $danger-70-color;
      }
      .header {
        .tag-num {
          color: $danger-70-color;
          border-color: $danger-70-color;
        }
      }
    }
    .header {
      .tag-num {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        border: 1px solid $border-base-color;
        border-color: $primary-70-color;
      }
    }
  }
}
</style>
