<template lang="pug">
.user-picker.flex.align-center.justify-stretch
  el-autocomplete.flex-1(
    clearable
    v-model="name"
    :fetch-suggestions="querySearch"
    placeholder="請輸入姓名",
    @select="handleSelect"
    @input="changeUser"
    @clear="$emit('clear')"
    :trigger-on-focus="false"
    :size="size"
    :disabled="disabled"
  )
</template>

<script>
import { getUsers, getPatients } from '@/api/user';

export default {
  props: {
    exclude: {
      type: Number,
      default: -1,
    },
    size: {
      type: String,
      default: 'normal',
    },
    proMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    default: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      options: [],
    };
  },
  computed: {
    searchAPI() {
      if (this.proMode) {
        return getPatients;
      }
      return getUsers;
    },
  },
  methods: {
    handleSelect(user) {
      this.$emit('input', user);
    },
    querySearch(query, cb) {
      this.loadKeywordUser(query).then((data) => {
        const options = data.filter((d) => d.id !== this.exclude).map((d) => ({
          ...d,
          value: `${d.family_name}${d.given_name} (${d.phone || '無電話'})`,
        }));
        options.sort((a, b) => a.value.localeCompare(b.value, 'zh-Hant'));
        cb(options);
      });
    },
    async loadKeywordUser(keyword) {
      if (keyword !== '') {
        try {
          const rsp = await this.searchAPI(0, 100, keyword);
          return rsp.data;
        } catch (e) {
          console.log(e);
        }
      }

      return [];
    },
    changeUser(val) {
      if (val === '') {
        this.handleSelect(undefined);
      }
    },
  },
  mounted() {
    this.name = this.default;
    this.$on('name', (data) => {
      this.name = data;
    });
  },
};
</script>

<style lang="scss" scoped>

</style>
