<template lang="pug">
el-select(
  v-model="localValue"
  @change="changeValue"
  :clearable="clearable"
  :disabled="disabled"
  :multiple="multiple"
  )
  el-option(
    v-for="branch in branches"
    :key="branch.id"
    :label="branch.name"
    :value="branch.id")
</template>

<script>
import { getBranches } from '@/api/place';

export default {
  props: {
    value: {
      type: [Number, String, Array],
      default: undefined,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
  data() {
    return {
      branches: [],
      localValue: undefined,
    };
  },
  computed: {
    disabled() {
      return this.branches.filter((b) => b.id !== '').length <= 1;
    },
  },
  methods: {
    async loadData() {
      const branches = (await getBranches(true)).filter((b) => b.public || b.public === undefined);
      if (this.showAll) {
        branches.unshift({
          id: undefined,
          name: '全部館別',
        });
      }
      this.branches = branches;
      if (!this.showAll && !this.multiple && this.localValue === undefined) {
        this.localValue = this.branches.filter((b) => b.id !== '')[0]?.id;
        this.$emit('input', this.localValue);
      }
    },
    changeValue() {
      if (!this.localValue) {
        this.$emit('input');
      } else {
        this.$emit('input', this.localValue);
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>

</style>
