/* eslint-disable no-param-reassign */

class ErrorList {
  constructor() {
    this.list = [];
  }

  get warnMessage() {
    if (this.list.length === 1) {
      const err = this.list[0];
      return {
        title: `請檢查${err.column}欄位`,
        message: err.msg,
      };
    }
    return {
      title: '請檢查輸入的欄位',
      message: `請確認以下欄位皆填寫正確: ${this.list.map((l) => l.column).join(', ')}`,
    };
  }

  get hasError() {
    return this.list.length > 0;
  }

  add(column, msg) {
    this.list.push({ column, msg });
  }
}

export default {
  install(Vue) {
    Vue.prototype.$initInputError = () => (new ErrorList());
  },
};
