import instance from './base';

export async function getPlaces() {
  return [
    {
      id: 1,
      text: '館別A',
      spaces: [{
        id: 1,
        text: '空間1',
      }, {
        id: 2,
        text: '空間2',
      }],
    },
    {
      id: 2,
      text: '館別B',
      spaces: [{
        id: 1,
        text: '空間2-1',
      }, {
        id: 2,
        text: '空間2-2',
      }],
    },
  ];
}

export async function addPlace() {
  return true;
}

const fakeBranches = [
  {
    id: 1,
    name: '館別1',
    public: true,
    rooms: [
      {
        id: 3,
        name: '空間1',
        public: true,
      },
      {
        id: 4,
        name: '空間2',
        public: false,
      },
    ],
  },
  {
    id: 2,
    name: '館別2',
    public: false,
    rooms: [
      {
        id: 5,
        name: '空間1',
        public: true,
      },
      {
        id: 6,
        name: '空間2',
        public: true,
      },
    ],
  },
];
let maxID = 2;

export async function getBranches(publicStatus) {
  if (window.mockMode) {
    return fakeBranches;
  }
  const rsp = await instance.get('/v1/branches', {
    params: {
      public: publicStatus,
    },
  });
  return rsp.data;
}

export async function addBranch(name, publicStatus, rooms) {
  const data = {
    name,
    public: publicStatus,
    rooms,
  };
  if (window.mockMode) {
    maxID += 1;
    fakeBranches.push({
      ...data,
      id: maxID,
    });
    return { id: maxID };
  }
  const rsp = await instance.post('/v1/branches', data);
  return rsp.data;
}

export async function updateBranch(id, name, publicStatus, origRooms, newRooms) {
  console.log({
    id, name, publicStatus, origRooms, newRooms,
  });
  const data = {
    name,
    public: publicStatus,
    added_rooms: newRooms.map((n) => ({
      name: n,
      public: true,
    })),
    updated_rooms: origRooms,
  };
  if (window.mockMode) {
    fakeBranches.forEach((b) => {
      if (b.id === id) {
        // eslint-disable-next-line no-param-reassign
        b.rooms = [];
        b.rooms.push(...origRooms);
        b.rooms.push(...newRooms);
        // eslint-disable-next-line no-param-reassign
        b.name = name;
        // eslint-disable-next-line no-param-reassign
        b.public = publicStatus;
      }
    });
    return {};
  }

  const rsp = await instance.put(`/v1/branches/${id}`, data);
  return rsp.data;
}
