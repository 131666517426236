import { getList } from 'country-list-with-dial-code-and-flag';

const countries = getList();
countries.sort((a, b) => (a.name > b.name ? 1 : -1));
const idx = countries.findIndex((c) => c.dial_code === '+886');
const tw = countries[idx];
tw.name = 'Taiwan';
countries.splice(idx, 1);
countries.unshift(tw);

export default countries;
