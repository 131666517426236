import instance from './base';

const fakeTags = [
  {
    id: 1,
    name: '新案',
    public: true,
  },
  {
    id: 2,
    name: '自帶案',
    public: true,
  },
  {
    id: 3,
    name: '家長諮詢',
    public: false,
  },
];
let maxID = 3;

function getFakeTags() {
  return fakeTags;
}

export async function getTags() {
  if (window.mockMode) {
    return getFakeTags();
  }
  const rsp = await instance.get('/v1/appointment_tags');
  const tags = rsp.data;

  tags.sort((a, b) => {
    if (!a.public) {
      return 1;
    }
    if (!b.public) {
      return -1;
    }
    return a.name.localeCompare(b.name, 'zh-Hant');
  });
  return tags;
}

export async function updateTag(id, name, publicStatus) {
  if (window.mockMode) {
    fakeTags.forEach((t) => {
      if (t.id === id) {
        // eslint-disable-next-line no-param-reassign
        t.name = name;
        // eslint-disable-next-line no-param-reassign
        t.public = publicStatus;
      }
    });
    return {};
  }
  const rsp = await instance.put(`/v1/appointment_tags/${id}`, {
    name,
    public: publicStatus,
  });
  return rsp.data;
}

export async function createTag(name, publicStatus) {
  if (window.mockMode) {
    const t = {
      id: maxID + 1,
      name,
      public: publicStatus,
    };
    fakeTags.push(t);
    maxID += 1;
    return { id: t.id };
  }
  const rsp = await instance.post('/v1/appointment_tags', {
    name,
    public: publicStatus,
  });
  return rsp.data;
}
