import instance from './base';

export async function getOrganizations() {
  const rsp = await instance.get('/v1/organizations');
  return rsp.data;
}

export async function getOrganizationDetail(orgID) {
  const rsp = await instance.get(`/v1/organizations/${orgID}`);
  return rsp.data;
}

export async function updateOrganization(orgID, name, publicStatus) {
  const rsp = await instance.put(`/v1/organizations/${orgID}`, {
    name,
    public: publicStatus,
  });
  return rsp.data;
}

export async function addOrganization(name) {
  const rsp = await instance.post('/v1/organizations', {
    name,
    public: true,
  });
  return rsp.data;
}

export async function uploadTemplateFile(oid, file) {
  console.log({ oid, file });
  const formData = new FormData();
  formData.append('file', file);
  const rsp = await instance.post(`/v1/organizations/${oid}/templates`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return rsp.data;
}

export async function createProject(oid, data) {
  const rsp = await instance.post(`/v1/organizations/${oid}/projects`, data);
  return rsp.data;
}

export async function updateProject(oid, pid, data) {
  const rsp = await instance.put(`/v1/organizations/${oid}/projects/${pid}`, data);
  return rsp.data;
}

export async function updateProjectPublic(oid, pid, publicStatus) {
  const rsp = await instance.patch(`/v1/organizations/${oid}/projects/${pid}/public`, {
    public: publicStatus,
  });
  return rsp.data;
}
