import instance from './base';

export async function getPatient(uid) {
  const rsp = await instance.get(`/v1/patients/${uid}`);
  return rsp.data;
}

export async function getPatientAppointments(uid, startAt, endAt) {
  const rsp = await instance.get(`/v1/patients/${uid}/appointments`, {
    params: {
      start_at: startAt,
      end_at: endAt,
    },
  });
  return rsp.data;
}

export async function getProPatientAppointments(uid, startAt, endAt) {
  const rsp = await instance.get(`/v1/patients/${uid}/appointments`, {
    params: {
      start_at: startAt,
      end_at: endAt,
    },
  });
  return rsp.data;
}

export async function getLastPatientRecord(uid) {
  const rsp = await instance.get(`/v1/patients/${uid}/last_appointment`);
  return rsp.data;
}

export async function getLastPatientRecordWithRecords(uid) {
  const rsp = await instance.get(`/v1/patients/${uid}/last_appointment_with_record`);
  return rsp.data;
}
