<template lang="pug">
.appointment-detail.flex
  .data.flex.flex-1.column.gap-16
    .col
      .title 個案姓名
      .data {{ data.user?.family_name }}{{data.user?.given_name}}
    .col
      .title 標記
      .data.flex.align-center.gap-6
        el-tag(v-for="n in data.tags", :key="n", type="info", size="small") {{ n }}
        el-select.selector-no-text.small(
          v-if="data.canEdit"
          multiple, v-model="data.tags", @change="updateBranchTags(data)")
          el-option(
            v-for="tag in tags",
            v-if="tag.public",
            :key="tag.name", :label="tag.name", :value="tag.name")
    .col
      .title 專案
      .data {{ projectName }}
    .col
      .title 專業人士
      .data
        pro-badge(:professional="data.professional")
    .col
      .title 本次諮商為
      .data.flex.align-center.gap-6
        .txt {{ $msg(`appointment.type.${detail.appointment_type}`) }}
        template(v-if="detail.appointment_type !== 'individual' && (detail.partners || []).length > 0")
          span (
          .partners.flex.align-center
            .partner.flex.align-center(
              @click="showPartner(p)"
              v-for="(p, idx) in data.partners")
              template
                span(v-if="idx !== 0") 、
                span.link-text.clickable {{ p.family_name }}{{ p.given_name }}
          span )
    .col
      .title 預約型態
      .data {{ $msg(`appointment.locationType.${detail.location_type}`)}}
    .col
      .title 預約時間與時長
      .data.flex.align-center.gap-6
        span {{ detail.startTime }}
        span ({{detail.length}} hr)
    .col
      .title 預約空間
      .data {{ detail.room?.branch?.name }} {{ detail.room?.name }}
    .col
      .title 預約價位
      .data NT $ {{ detail.price }}
    .col
      .title 支付記錄
      .data.flex.align-center.gap-6(:class="{'danger-color': detail.payment === 'notpaid'}")
        span {{ $msg(`appointment.payment.${detail.payment}`) }}
        el-dropdown(
          v-if="paidCanChange(detail.payment) && detail.canChangePaid"
          @command="updatePayment($event)", trigger="click")
          el-button.dropdown-button(icon="el-icon-arrow-down", size="mini")
          el-dropdown-menu(slot="dropdown")
            el-dropdown-item(command='notpaid') 尚未付款
            el-dropdown-item(command='cash') 現金支付
            el-dropdown-item(command='transfer') 轉帳支付
            el-dropdown-item(command='creditcard') 信用卡
    .col
      .title 個案記錄
      .data -
    .col
      .title 本次預約備註
      .data {{ detail.note || '-' }}
  .op
    el-dropdown(
      @command="execCommand($event)")
      el-button.dropdown-button(size="mini")
        .flex.align-center.gap-6
          span 預約操作
          i.el-icon-arrow-down
      el-dropdown-menu(slot="dropdown")
        el-dropdown-item(command="send") 傳送訊息給此個案
        el-dropdown-item(command="edit"
          :disabled="!detail.canEdit && !isAdmin") 編輯預約
        el-dropdown-item 下載此筆付款收據
        el-dropdown-item(:disabled="detail.location_type === 'inhouse'", command="copyLink") 複製諮商連結
        el-dropdown-item(command="cancel"
          :disabled="!detail.canCancel && !isAdmin") 取消此預約記錄
  person-viewer(
    hide-detail
    hide-open-chat
    append-to-body
    ref="personViewer"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getUser,
} from '@/api/user';
import PersonViewer from '@/components/drawers/PersonViewer.vue';
import constant from '@/util/constant';

import {
  updateAppointmentTags,
  getAppointment,
  updatePayment,
} from '@/api/reserve';

export default {
  name: 'appointment-detail',
  components: {
    PersonViewer,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array,
      default: () => {},
    },
    orgMap: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'isAdvStaff']),
    dataID() {
      return this.data?.id;
    },
    projectName() {
      if (this.orgMap[this.detail?.project?.id]) {
        return `${this.orgMap[this.detail?.project?.id].name} - ${this.detail?.project?.name}`;
      }
      return this.detail?.project?.name || '--';
    },
  },
  watch: {
    dataID() {
      if (this.dataID) {
        this.loadData();
      }
    },
  },
  methods: {
    showPartner(p) {
      this.$refs.personViewer.$emit('show', {
        type: 'id',
        data: p.id,
      });
    },
    paidCanChange(payment) {
      return ['notpaid', 'cash', 'transfer'].indexOf(payment) >= 0;
    },
    editAppointment() {
      this.$refs.appointmentEdit.$emit('show', this.data);
    },
    updatePayment(method) {
      this.$execWithLoading(async () => {
        await updatePayment(this.data.id, method);
        // eslint-disable-next-line no-param-reassign
        this.data.payment = method;
        this.detail.payment = method;
        this.$showSuccess('更新成功');
      }, () => {
        this.$showError('更新失敗，請稍後再試');
      });
    },
    async updateBranchTags(branch) {
      return this.$execWithLoading(async () => {
        await updateAppointmentTags(branch.id, branch.tags);
        this.$showSuccess('已更新標記');
      }, () => {
        this.$showError('更新標記失敗');
      });
    },
    async loadData() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayTs = parseInt(today.getTime() / 1000, 10);

      const now = new Date();
      const nowTs = parseInt(now.getTime() / 1000, 10);

      return this.$execWithLoading(async () => {
        const rsp = await getAppointment(this.data.id);
        this.detail = {
          ...rsp,
          startTime: this.$timestampToDateTimeMinute(rsp.start_at),
          length: ((rsp.end_at - rsp.start_at) / 3600).toFixed(2),
          canEdit: rsp.start_at >= todayTs,
          canCancel: nowTs < rsp.end_at + 86400,
          canChangePaid: nowTs < rsp.end_at + 86400,
        };
      }, () => {
        this.$showError('讀取詳細記錄失敗');
      });
    },
    execCommand(cmd) {
      const appointment = this.detail;

      if (cmd === 'edit') {
        this.$emit('edit', appointment);
      } else if (cmd === 'send') {
        this.tryOpenChat(appointment.user.id);
      } else if (cmd === 'copyLink') {
        this.$copy(appointment.meeting_link, true);
      } else if (cmd === 'cancel') {
        this.$emit('cancel', appointment);
      }
    },
    async tryOpenChat(userID) {
      return this.$execWithLoading(async () => {
        const data = await getUser(userID);
        if (data.line_chat_id && data.line_chat_id !== '') {
          this.$goLink(`${constant.lineChatURL}/${data.line_chat_id}`);
        } else {
          this.$showError('該使用者尚未設定 LINE 聊天 ID');
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadData();
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.appointment-detail {
  .op {
    flex: 0 0 auto;
  }
  .data {
    .title {
      color: $text-secondary-color;
      font-size: 12px;
    }
  }
}
</style>
