<template lang="pug">
el-drawer(
  title="個案簡歷"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.gap-20.default
    .name-block.flex.column.gap-10
      .name-row.flex.align-center.justify-space-between
      .name {{data.family_name}}{{data.given_name}}
        el-button(
          v-if="!hideEdit"
          icon="el-icon-edit-outline", round, size="mini"
          @click="editPerson")
      .id 個案編號: {{ data.id }}
      .operation.flex.gap-10(v-if="!hideDetail || !hideOpenChat || showOpenRecord")
        el-button(
          v-if="showOpenRecord"
          type="primary", plain, size="small"
          @click="showPersonRecord") 查看紀錄
        el-button(
          v-if="!hideDetail"
          type="primary", plain, size="small"
          @click="showDetail") 查看詳細資訊
        line-chat-id-edit-button(:id="data.line_chat_id", :uid="data.id")
    .seperator
    .info-block.flex.column.gap-10
      .info-column.flex.column.align-start(
        v-for="col in columns"
        v-if="!(simpleMode && col.advanceOnly)"
      )
        .colname {{ col.text }}
        template(v-if="col.converter")
          .value {{ col.converter(data[col.key]) }}
        template(v-else)
          .value {{ data[col.key] || '-' }}
      .info-column.flex.column.align-start(v-if="!simpleMode")
        .colname 地址
        .value {{ data.country }}
        .value {{ data.county }}{{ data.district }}
        .value {{ data.address }}
      .info-column.flex.column.align-start
        .colname.danger-dark-color 緊急聯絡人
        template(v-if="data.emergency_person && data.emergency_person !== ''")
          .value {{ data.emergency_person }} ({{ data.emergency_relationship }})
          .value {{ $getShowPhone(data.emergency_contact) }}
        template(v-else)
          .value -
      .info-column.flex.column.align-start(
        v-for="col in additionalColumns"
        v-if="!(simpleMode && col.advanceOnly)"
      )
        .colname {{ col.text }}
        template(v-if="col.converter")
          .value {{ col.converter(data[col.key]) }}
        template(v-else)
          .value {{ data[col.key] || '-' }}
  person-editor(
    :pro-edit="proEdit"
    @finish="updateResult"
    ref="personEditor", :append-to-body="true")
</template>

<script>
import PersonEditor from '@/components/drawers/PersonEdit.vue';
import { getUser } from '@/api/user';
import { getPatient } from '@/api/patient';

export default {
  name: 'person-viewer',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
    hideDetail: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideOpenChat: {
      type: Boolean,
      default: false,
    },
    showOpenRecord: {
      type: Boolean,
      default: false,
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
    patientMode: {
      type: Boolean,
      default: false,
    },
    proEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    'person-editor': PersonEditor,
  },
  data() {
    return {
      drawer: false,
      data: {},
      columns: [
        {
          text: '生理性別',
          key: 'gender',
          converter: (key) => ({
            male: '男性',
            female: '女性',
            other: '其他',
          }[key] || '-'),
        },
        {
          text: '稱謂',
          key: 'wanna_be_called',
        },
        {
          text: '出生年月日',
          key: 'birthday',
          converter: (day) => {
            if (day && day.indexOf && day.indexOf('-') >= 0) {
              return day;
            }
            return this.$timestampToDate(day) || '-';
          },
        },
        {
          text: '身分證字號',
          key: 'id_number',
        },
        {
          text: '護照號碼',
          key: 'passport',
        },
        {
          text: '聯絡電話',
          key: 'phone',
          advanceOnly: true,
          converter: (phone) => this.$getShowPhone(phone),
        },
        {
          text: 'Email',
          key: 'email',
          advanceOnly: true,
        },
        {
          text: '感情狀態',
          key: 'relationship',
          converter: (key) => this.$tryMsg(`appointment.relationship.${key}`) || '未知',
        },
        {
          text: '最高學歷',
          key: 'education',
          converter: (key) => this.$tryMsg(`appointment.education.${key}`) || '未知',
        },
        {
          text: '產業',
          key: 'industry',
        },
        {
          text: '職業',
          key: 'job',
        },
        {
          text: '職位',
          key: 'job_position',
        },
        {
          text: '年資',
          key: 'job_tenure',
        },
      ],
      additionalColumns: [
        {
          text: '語言',
          key: 'prefer_language',
          advanceOnly: true,
        },
        {
          text: '案件來源',
          key: 'refer_source',
          advanceOnly: true,
        },
        {
          text: '就醫紀錄/諮商經驗',
          key: 'therapy_history',
          advanceOnly: true,
        },
        {
          text: '是否嘗試過自殺',
          key: 'suicide_record',
          advanceOnly: true,
        },
      ],
    };
  },
  computed: {
    dataAPI() {
      if (this.patientMode) {
        return getPatient;
      }
      return getUser;
    },
  },
  methods: {
    handleClose(done) {
      done();
    },
    showPersonRecord() {
      this.$emit('showProRecord', this.data.id);
      this.drawer = false;
    },
    showDetail() {
      this.$emit('showDetail', this.data);
      this.drawer = false;
    },
    show(data) {
      this.drawer = true;
      if (data.type === 'id') {
        if (this.data.id !== data.data) {
          this.data = {};
        }
        setTimeout(async () => {
          if (this.data.id === data.data) {
            const user = await this.dataAPI(data.data);
            console.log(user);
            this.data = user;
            return;
          }
          this.$execWithLoading(async () => {
            const user = await this.dataAPI(data.data);
            console.log(user);
            this.data = user;
          }, () => {
            this.data = { id: data.data };
          });
        }, 100);
      } else {
        this.data = data.data;
      }
    },
    editPerson() {
      this.$refs.personEditor.$emit('show', {
        type: 'data',
        data: this.data,
      });
    },
    updateResult(result) {
      if (result) {
        this.show({
          type: 'id',
          data: this.data.id,
        });
      }
      this.$forceUpdate();
    },
  },
  mounted() {
    this.$on('show', (data) => {
      this.show(data);
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.drawer {
  padding: 0 20px;
  padding-bottom: 20px;
  .name-block {
    .name-row {
      .name {
        font-size: 20px;
      }
    }
  }
  .seperator {
    border-bottom: 1px solid $border-base-color;
  }
  .info-block {
    overflow: auto;
    overflow: overlay;
    .info-column {
      .colname {
        font-size: 12px;
        color: $text-secondary-color;
      }
      .value {
        color: $text-regular-color;
      }
    }
  }

}
</style>
