<template lang="pug">
.pro-badge.flex.align-center.gap-6(v-if="professional !== undefined")
  img(:src="url")
  .name.flex.align-center.wrap
    template {{ professional?.family_name || '' }}
    template {{ professional?.given_name || '' }}
</template>

<script>
import { getProfessionalAvatarURL } from '@/api/professionals';

export default {
  name: 'pro-badge',
  props: {
    professional: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: '',
    };
  },
  watch: {
    professional() {
      this.updateURL();
    },
  },
  methods: {
    async updateURL() {
      if (this.professional.avatar) {
        this.url = this.professional.avatar;
      } else {
        this.url = await getProfessionalAvatarURL(this.professional.id);
      }
    },
  },
  mounted() {
    this.updateURL();
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 36px;
  height: 36px;
  flex: 0 0 36px;
  border-radius: 18px;
  object-fit: cover;
}
</style>
