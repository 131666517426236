// let manageHost = 'http://management.local.com:11280';
// let proHost = 'http://pro.local.com:11280';
// let appHost = 'http://app.local.com:11280';
let lineClientID = 2001412924;
let manageHost = 'https://lmd.morph.tw';
let proHost = 'https://pro.morph.tw';
let appHost = 'https://app.morph.tw';
let lineChatURL = 'https://chat.line.biz/Ub44dfa756ceb63eb2a15002c8cb5bca7/chat';

const jobList = [
  '農、林、漁、牧業',
  '礦業及土石採取業',
  '製造業',
  '電力及燃氣供應業',
  '用水供應及污染整治業',
  '營建工程業',
  '批發及零售業',
  '運輸及倉儲業',
  '住宿及餐飲業',
  '出版影音及資通訊業',
  '金融及保險業',
  '不動產業',
  '專業、科學及技術服務(法律/會計/企管/顧問)',
  '租賃/人力仲介/旅行/保全',
  '政府機關',
  '軍人/警察/消防員',
  '教育業',
  '醫療保健及社會工作服務業',
  '藝術、娛樂及休閒服務業',
  '其他服務業(美容美髮/汽修/家事服務/生命禮儀/公會)',
  '自由業/接案工作',
  '家管/待業中/學生/其他',
];

if (window.location.href.indexOf('-stg') >= 0) {
  manageHost = 'https://lmd-stg.morph.tw';
  proHost = 'https://pro-stg.morph.tw';
  appHost = 'https://app-stg.morph.tw';
  lineClientID = 1661105685;
  lineChatURL = 'https://chat.line.biz/U42a6fe5c193cab0dd345e128eadec69f/chat';
} else if (window.location.href.indexOf('-dev') >= 0 || window.location.href.indexOf('local') >= 0) {
  manageHost = 'https://lmd-dev.morph.tw';
  proHost = 'https://pro-dev.morph.tw';
  appHost = 'https://app-dev.morph.tw';
  lineClientID = 1661093354;
  lineChatURL = 'https://chat.line.biz/xxxx/chat';
}

export default {
  manageHost,
  proHost,
  appHost,
  lineClientID,
  jobList,
  lineChatURL,
};
