<template lang="pug">
.activity-block.flex.align-stretch.justify-stretch.clickable(
  @click="$emit('click')"
  :data-start-at="activity.start_at"
  :data-start="startTime"
  :length="length"
  :style="style")
  .block.flex-1.flex.column.gap-4
    .header.flex.align-center.gap-10
      .name.flex-1.flex.align-center.justify-start.gap-6
        .pro {{ activity.professional.family_name }}{{ activity.professional.given_name }}
    .title.bold 課程：{{ activity.name }}
    .unit(v-if="multiLine") {{ activity.organization }}
</template>

<script>
export default {
  props: {
    rooms: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      style: {},
      multiLine: false,
      startAt: 0,
      startTime: '',
      length: 0,
    };
  },
  methods: {
    computeStyle() {
      const start = new Date(this.activity.start_at * 1000);

      const startSlot = Math.floor(this.activity.start_at / 1800);
      const endSlot = Math.ceil(this.activity.end_at / 1800);
      const length = endSlot - startSlot;
      let height = 60 * length;
      if (height < 60) {
        height = 60;
      }
      this.multiLine = height >= 48;
      const hour = start.getHours();
      const minute = start.getMinutes();
      const index = this.rooms.findIndex((r) => r.id === this.activity.room.id);
      if (index === -1) {
        return {
          display: 'none',
        };
      }
      this.startTime = `${hour}:${minute}`;
      this.length = length;

      return {
        height: `${height}px`,
        top: `${60 + 60 * 2 * hour + (minute >= 30 ? 60 : 0)}px`,
        left: `${80 + 160 * index}px`,
      };
    },
  },
  mounted() {
    this.style = this.computeStyle();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.activity-block {
  position: absolute;
  box-sizing: border-box;
  width: 160px;
  padding: 2px;
  .block {
    border-radius: 4px;
    padding: 5px;
    background: #DFF2F1;
    border: 1px solid #02A1A1;
  }
}
</style>
