import instance from './base';

export async function getAccountingModels() {
  const rsp = await instance.get('/v1/accounting/models');
  return rsp.data;
}

export async function createAccounting() {
  return '';
}
