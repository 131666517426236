import instance from './base';

export async function createUser(user) {
  const rsp = await instance.post('/v1/users', user);
  return rsp.data;
}
export async function updateUser(id, user) {
  const rsp = await instance.put(`/v1/users/${id}`, user);
  return rsp.data;
}
export async function updateProUser(id, user) {
  const rsp = await instance.put(`/v1/patients/${id}`, user);
  return rsp.data;
}

export async function getUser(uid) {
  const rsp = await instance.get(`/v1/users/${uid}`);
  return rsp.data;
}

export async function getUsers(page, limit, keyword) {
  const rsp = await instance.get('/v1/users', {
    params: {
      page,
      limit,
      keyword,
    },
  });
  return rsp.data;
}

// getPatients is same API with getUsers in professional environment
export async function getPatients(page, limit, keyword) {
  const rsp = await instance.get('/v1/patients', {
    params: {
      page,
      limit,
      keyword,
    },
  });
  return rsp.data;
}

export async function getUserAppointments(uid, startAt, endAt) {
  const rsp = await instance.get(`/v1/users/${uid}/appointments`, {
    params: {
      start_at: startAt,
      end_at: endAt,
    },
  });
  return rsp.data;
}

export async function updateUserStatus(uid, state) {
  const rsp = await instance.put(`/v1/users/${uid}/status`, {
    state,
  });
  return rsp.data;
}

export async function updateUserNote(uid, note) {
  const rsp = await instance.put(`/v1/users/${uid}/note`, {
    note,
  });
  return rsp.data;
}

export async function getUserAttachments(uid) {
  const rsp = await instance.get(`/v1/users/${uid}/attachments`);
  return rsp.data;
}

export async function updateUserAttachments(uid, file) {
  const formData = new FormData();
  formData.append('attachment', file);
  const rsp = await instance.post(`/v1/users/${uid}/attachments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return rsp.data;
}

export async function deleteAttach(url) {
  const rsp = await instance.delete(url);
  return rsp.data;
}

export async function createUserForm(uid) {
  const rsp = await instance.get(`/v1/users/${uid}/form`);
  return rsp.data.url;
}

export async function submitForm(key, profile) {
  const rsp = await instance.post('/v1/form', {
    ...profile,
    key,
  });
  return rsp.data;
}
