<template lang="pug">
.slide-toggle(:class="{'on': innerValue}", @click="toggle").clickable
  input(
    type="checkbox" id="slide" name="check"
    v-model="innerValue")
  label
  .on-label.status(v-if="innerValue") {{ activeText }}
  .off-label.status(v-else) {{ inactiveText }}
</template>

<script>
export default {
  props: {
    activeText: {
      type: String,
      default: '',
    },
    inactiveText: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: true,
    },
    beforeChange: {
      type: Function,
      default: undefined,
    },
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
  },
  data() {
    return {
      innerValue: true,
    };
  },
  methods: {
    updateValue() {
      this.$emit('input', this.innerValue);
      this.$forceUpdate();
    },
    toggle(event) {
      if (this.beforeChange !== undefined) {
        let ret = false;
        if (this.$isPromise(this.beforeChange)) {
          try {
            ret = this.beforeChange.apply(this);
          } catch (e) {
            console.log(e);
            return;
          }
        } else {
          ret = this.beforeChange();
        }
        if (!ret) {
          return;
        }
      }
      this.innerValue = !this.innerValue;
      this.updateValue();
      event.stopPropagation();
    },
  },
  mounted() {
    this.innerValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.slide-toggle {
  width: 60px;
  flex: 0 0 60px;
  height: 20px;
  position: relative;
  border-radius: 50px;
  background: #C1CBDB;
  &.on {
    background: $primary-color;
    label {
      left: 42px;
    }
  }
  label {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 1;
    background: #fcfff4;
    transition: all 0.4s ease;
  }
  input[type=checkbox] {
    visibility: hidden;
  }
  .status {
    position: absolute;
    color: white;
  }
  .on-label {
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
  .off-label {
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}
</style>
