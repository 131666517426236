import instance from './base';

export async function getActivities(params) {
  const rsp = await instance.get('/v1/activities', { params });
  return rsp.data;
}

export async function getActivity(id) {
  const rsp = await instance.get(`/v1/activities/${id}`);
  return rsp.data;
}

export async function deleteActivity(id) {
  const rsp = await instance.delete(`/v1/activities/${id}`);
  return rsp.data;
}

export async function updateActivity(id, data) {
  const rsp = await instance.put(`/v1/activities/${id}`, data);
  return rsp.data;
}

export async function createActivity(data) {
  const rsp = await instance.post('/v1/activities', data);
  return rsp.data;
}
