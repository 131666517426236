import { render, staticRenderFns } from "./RelationSelect.vue?vue&type=template&id=fa7d9c38&scoped=true&lang=pug"
import script from "./RelationSelect.vue?vue&type=script&lang=js"
export * from "./RelationSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa7d9c38",
  null
  
)

export default component.exports