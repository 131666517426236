import axios from 'axios';

const instance = axios.create({
  baseURL: '/api',
  withCredentials: true,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((request) => {
  try {
    if (request.headers['Content-Type'] === 'application/json') {
      if (request.data) {
        const data = JSON.parse(JSON.stringify(request.data));
        Object.keys(data).forEach((key) => {
          if (data[key] === '') {
            delete data[key];
          }
          if (data[key] === ' ') {
            data[key] = '';
          }
        });
        // eslint-disable-next-line no-param-reassign
        request.data = JSON.stringify(data);
      }
    }
  } catch (e) {
    console.log(e);
  }
  return request;
});

instance.interceptors.response.use((response) => response, (error) => {
  console.log(error);
  // if (error.response.status === 403) {
  //   document.location.href = '/#/login';
  // }
  throw error;
});

export default instance;
