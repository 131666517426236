/* eslint-disable no-param-reassign */
import msg from '@/util/msg';

export default {
  install(Vue) {
    Vue.prototype.$msg = (key) => {
      const arr = key.split('.');
      let val = msg;
      for (let i = 0; i < arr.length && val !== undefined; i += 1) {
        val = val[arr[i]];
      }
      return val || key;
    };
    Vue.prototype.$tryMsg = (key) => {
      const arr = key.split('.');
      let val = msg;
      for (let i = 0; i < arr.length && val !== undefined; i += 1) {
        val = val[arr[i]];
      }
      return val || '';
    };
  },
};
