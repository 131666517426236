<template lang="pug">
el-drawer(
  :title="title"
  :visible.sync="drawer"
  direction="rtl"
  :wrapperClosable="false"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.gap-20.default
    .data.flex.column.align-stretch.gap-6
      .title 活動名稱*
      el-input.input(v-model="name" placeholder="請輸入活動名稱")
    .data.flex.column.align-stretch.gap-6
      .title 單位*
      el-input.input(v-model="organization" placeholder="請輸入單位名稱")
    .data.flex.column.align-stretch.gap-6
      .title 專業人士*
      pro-selector.flex-1(v-model="professional")
    .data.flex.column.align-stretch.gap-6
      .title 對象描述
      el-input.input(
        type="textarea"
        :rows="2"
        :maxlength="100"
        show-word-limit
        v-model="description" placeholder="請輸入內容")
    .data.flex.column.align-stretch.gap-6
      .title 活動時間*
      .value.flex.align-center.gap-6
        el-date-picker(
          :clearable="false"
          v-model="activityDay")
        el-time-picker(
          is-range
          :clearable="false"
          ref='timeSelector'
          v-model="time"
           range-separator="至"
          start-placeholder="請選擇開始時間"
          end-placeholder="請選擇結束時間"
          :picker-options="{format: 'HH:mm'}")
    .data.flex.column.align-stretch.gap-6
      .title 活動類型
      el-radio-group(v-model="type" size="small")
        el-radio(label="inhouse" border) 館內活動
        el-radio(label="virtual" border) 線上活動
        el-radio(label="otherplace" border) 外展活動
    template(v-if="type === 'otherplace'")
      .data.flex.column.align-stretch.gap-6
        .title 縣市
        el-select.input(
          autocomplete="off"
          filterable
          v-model="city" placeholder="請輸入縣市")
          el-option(
            v-for="county in counties"
            :key="county"
            :label="county"
            :value="county")
      .data.flex.column.align-stretch.gap-6
        .title 鄉鎮區
        el-select.input(
          filterable
          :disabled="city === undefined || city === ''"
          v-model="district" placeholder="請輸入鄉鎮")
          el-option(
            v-for="d in districts"
            :key="d"
            :label="d"
            :value="d")
      .data.flex.column.align-stretch.gap-6
        .title 地址
        el-input.input(v-model="address", placeholder="請輸入地址")
    template(v-else-if="type === 'inhouse' || type === 'virtual'")
      .data.flex.column.align-stretch.gap-6
        .title 場地空間
        el-select(
          v-model="branch", placeholder="請選擇館別")
          el-option(v-for="b in branchList", :key="b.id", :value="b.id", :label="b.name")
        .flex.align-center.gap-20
          el-select.flex-1(
            v-model="room",
            placeholder="請選擇空間", :disabled="branch === undefined")
            el-option(v-for="r in roomList", :key="r.id", :value="r.id", :label="r.name")
          el-button(
            :disabled="branch === undefined || activityDay === undefined"
            type="text", @click="toggleRemainSpace") 查看當日空間
    .data.flex.column.align-stretch.gap-6(v-if="type === 'virtual'")
      .title 視訊連結
      el-input.input(v-model="link" placeholder="請輸入視訊連結")
    .data.flex.column.align-stretch.gap-6
      .title 此活動備註
      el-input.input(
        type="textarea"
        :rows="2"
        :maxlength="100"
        show-word-limit
        v-model="note" placeholder="請輸入內容")
    .flex.align-center.gap-12
      .data.flex.column.align-stretch.gap-6
        .title 鐘點總計*
        el-input.input(v-model="total" placeholder="NT$")
      .data.flex.column.align-stretch.gap-6
        .title 鐘點入帳方式
        el-radio-group.flex.gap-6(v-model="incomeType")
          el-radio.radio(label="organization" border) 機構
          el-radio.radio(label="professional" border) 專業人士
    .data.flex.column.align-stretch.gap-6
      .title 分帳模組*
      el-select(v-model="accounting", placeholder="無")
        el-option(
          v-for="a in accountingList"
          v-if="a.public"
          :key="a.id", :value="a.id", :label="a.name")
    .flex.align-center.gap-12
      .data.flex.column.align-stretch.gap-6
        .title 雜費
        el-input.input(v-model="misc" placeholder="NT$")
      .data.flex.column.align-stretch.gap-6
        .title 雜費入帳方式
        el-radio-group.flex.gap-6.wrap(v-model="miscType")
          el-radio.radio(label="organization" border) 機構
          el-radio.radio(label="professional" border) 專業人士
          el-radio.radio(label="paid_by_organization" border) 機構自籌
    .flex.align-center.gap-12
      .data.flex.column.align-stretch.gap-6
        .title 交通費
        el-input.input(v-model="traffic" placeholder="NT$")
      .data.flex.column.align-stretch.gap-6
        .title 交通費入帳方式
        el-radio-group.flex.gap-6.wrap(v-model="trafficType")
          el-radio.radio(label="organization" border) 機構
          el-radio.radio(label="professional" border) 專業人士
          el-radio.radio(label="paid_by_organization" border) 機構自籌
    .op.flex.align-center-justify-stretch.gap-10
      el-button.flex-1(@click="cancel") 取消
      el-button.flex-1(v-if="editMode", @click="save", type="primary") 更新
      el-button.flex-1(v-else, @click="save", type="primary") 新增
  appointment-room-view.space-view(
    :clickable="false"
    v-if="showSpace",
    :hour="showHour",
    :branch="branch", :time="activityDay", public)
</template>

<script>
import { getBranches } from '@/api/place';
import { createActivity, getActivity, updateActivity } from '@/api/activity';
import { getAccountingModels } from '@/api/accountModel';
import AppointmentRoomView from '@/components/AppointmentRoomView.vue';
import {
  getCounties,
  getDistricts,
} from '@/util/address';
import ProfessionalSelectorVue from '@/components/ProfessionalSelector.vue';

export default {
  name: 'activity-editor',
  components: {
    'appointment-room-view': AppointmentRoomView,
    'pro-selector': ProfessionalSelectorVue,
  },
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const counties = getCounties();
    const [start, end] = this.getDefaultStartEnd();
    return {
      drawer: false,
      editMode: false,

      name: '',
      organization: '',
      professional: undefined,
      description: '',
      date: [],
      activityDay: undefined,
      type: 'inhouse',
      branch: undefined,
      room: undefined,
      link: '',
      note: '',
      total: '',
      misc: '',
      traffic: '',
      city: '',
      district: '',
      address: '',
      accounting: undefined,
      incomeType: 'organization',
      miscType: 'organization',
      trafficType: 'organization',

      time: [start, end],

      branchList: [],
      roomList: [],
      accountingList: [],
      counties,
      showSpace: false,
      showHour: 9,
      origId: undefined,
    };
  },
  computed: {
    title() {
      if (this.editMode) {
        return '編輯活動';
      }
      return '新增活動';
    },
    startDate() {
      return this.date[0];
    },
    districts() {
      return this.getDistricts(this.city);
    },
  },
  watch: {
    branch() {
      if (this.branch === undefined) {
        this.roomList = [];
        return;
      }
      this.roomList = this.branchMap[this.branch]?.rooms || [];
    },
    total() {
      this.total = this.purifyNumber(this.total);
    },
    misc() {
      this.misc = this.purifyNumber(this.misc);
    },
    traffic() {
      this.traffic = this.purifyNumber(this.traffic);
    },
    city() {
      const districts = this.getDistricts(this.city);
      if (districts.indexOf(this.district) < 0) {
        this.district = '';
      }
    },
  },
  methods: {
    getDefaultStartEnd() {
      const start = new Date();
      const end = new Date();
      start.setHours(9, 0, 0, 0);
      end.setHours(23, 0, 0, 0);
      return [start, end];
    },
    getDistricts,
    checkInput() {
      const errs = this.$initInputError();
      if (this.name === '') {
        errs.add('活動名稱', '請輸入活動名稱');
      }
      if (this.organization === '') {
        errs.add('單位', '請輸入單位');
      }
      if (this.professional === undefined) {
        errs.add('專業人士', '請選擇專業人士');
      }
      if (this.activityDay === undefined) {
        errs.add('活動時間', '請選擇活動時間');
      }
      switch (this.type) {
        case 'otherplace':
          if (this.city === '' || this.district === '' || this.address === '') {
            errs.add('外展地址', '請輸入縣市、鄉鎮區、外展地址');
          }
          break;
        case 'inhouse':
          if (this.branch === undefined || this.room === undefined) {
            errs.add('場地空間', '請選擇場地空間');
          }
          break;
        default:
          break;
      }
      if (this.total === '') {
        errs.add('鐘點統計', '請輸入鐘點總計');
      }
      if (this.accounting === '' || this.accounting === undefined) {
        errs.add('分帳模組', '請選擇分帳模組');
      }

      if (errs.hasError) {
        const msg = errs.warnMessage;
        this.$showWarn(msg.title, msg.message);
        return false;
      }

      return true;
    },
    purifyNumber(input) {
      let value = input.toString().trim();
      value = value.replace(/[^0-9]/g, '');
      return value === '' ? '0' : value;
    },
    cancel() {
      this.close(false);
    },
    handleClose() {
      this.close(false);
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
    },
    async loadBranchs() {
      const branches = await getBranches(true);
      this.branchList = branches.filter((b) => b.public);
      const m = {};
      branches.forEach((b) => {
        m[b.id] = b;
      });
      this.branchMap = m;
    },
    async loadAccountings() {
      const rsp = await getAccountingModels();
      this.accountingList = rsp.filter((a) => (a.model_type === 'activity'));
    },
    async loadData(id) {
      let msg = '載入選項中';
      if (id !== undefined) {
        msg = '讀取中';
      }
      await this.$execWithLoadingMessage(msg, async () => {
        await Promise.all([
          this.loadBranchs(),
          this.loadAccountings(),
        ]);
        if (id !== undefined) {
          this.editMode = true;
          this.origId = id;
          const activity = await getActivity(id);
          this.setActivity(activity);
        }
      });
    },
    setActivity(activity) {
      this.name = activity.name;
      this.professional = activity.professional.id;
      this.organization = activity.organization;
      const start = new Date(activity.start_at * 1000);
      const end = new Date(activity.end_at * 1000);
      this.time = [start, end];
      this.activityDay = new Date(activity.start_at * 1000);
      this.type = activity.location_type;
      this.total = activity.price.toString();
      this.incomeType = activity.payment_received_by;
      this.accounting = activity.price_model?.id;
      this.misc = activity.incidental_expense;
      this.miscType = activity.incidental_expense_by;
      this.traffic = activity.transportation_fare;
      this.trafficType = activity.transportation_fare_by;
      this.link = activity.meeting_link || '';
      this.branch = activity.room?.branch?.id;
      this.room = activity.room?.id;
      this.city = activity.city || '';
      this.district = activity.district || '';
      this.address = activity.address || '';
      try {
        const data = JSON.parse(activity.description);
        if (typeof data === typeof []) {
          this.description = data[0] || '';
          this.note = data[1] || '';
        }
      } catch (e) {
        this.description = activity.description;
      }
    },
    toggleRemainSpace() {
      if (this.showSpace === false) {
        const [start] = this.time;
        let hour = start.getHours();
        const minute = start.getMinutes();
        if (minute !== 0) {
          hour += 0.5;
        }
        this.showHour = hour;
      }
      this.showSpace = !this.showSpace;
    },
    generateActivity() {
      const start = new Date(this.activityDay);
      const end = new Date(this.activityDay);

      const [startTime, endTime] = this.time;

      start.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
      end.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

      const ret = {
        name: this.name,
        professional: this.professional,
        organization: this.organization,
        description: JSON.stringify([this.description, this.note]),
        start_at: parseInt(start.getTime() / 1000, 10),
        end_at: parseInt(end.getTime() / 1000, 10),
        location_type: this.type,
        price: parseInt(this.total, 10),
        payment_received_by: this.incomeType,
        model: this.accounting,
        incidental_expense: parseInt(this.purifyNumber(this.misc), 10),
        incidental_expense_by: this.miscType,
        transportation_fare: parseInt(this.purifyNumber(this.traffic), 10),
        transportation_fare_by: this.trafficType,
      };
      if (this.type === 'inhouse') {
        ret.room = this.room;
      } else if (this.type === 'virtual') {
        ret.meeting_link = this.link;
      } else if (this.type === 'otherplace') {
        ret.city = this.city;
        ret.district = this.district;
        ret.address = this.address;
      }
      return ret;
    },
    async save() {
      if (!this.checkInput()) {
        return false;
      }
      return this.$execWithLoadingMessage('儲存中', async () => {
        if (this.editMode) {
          await updateActivity(this.origId, this.generateActivity());
          this.$showSuccess('更新成功');
        } else {
          await createActivity(this.generateActivity());
          this.$showSuccess('新增成功');
        }
        this.close(true);
      }, (e) => {
        if (this.editMode) {
          this.$showAxiosException('更新失敗', e);
        } else {
          this.$showAxiosException('新增失敗', e);
        }
      });
    },
    reset() {
      const [start, end] = this.getDefaultStartEnd();
      this.editMode = false;
      this.name = '';
      this.organization = '';
      this.professional = undefined;
      this.description = '';
      this.date = [];
      this.activityDay = undefined;
      this.type = 'inhouse';
      this.branch = undefined;
      this.room = undefined;
      this.link = '';
      this.note = '';
      this.total = '';
      this.misc = '';
      this.traffic = '';
      this.city = '';
      this.district = '';
      this.address = '';
      this.accounting = undefined;
      this.incomeType = 'organization';
      this.miscType = 'organization';
      this.trafficType = 'organization';
      this.time = [start, end];
    },
  },
  mounted() {
    this.$on('show', (activity) => {
      this.reset();
      this.show();
      setTimeout(async () => {
        await this.loadData(activity?.id);
      }, 100);
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
}
.el-date-editor--time-select {
  flex: 0 0 100px;
}
.space-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 30vw;
  height: calc(100vh - 40px);
  height: calc(100dvh - 40px);
}
.radio {
  margin: 0;
  margin-left: 0 !important;
}
</style>
