<template lang="pug">
el-drawer(
  title="新增預約"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.align-stretch.gap-12
    .op.flex.column.align-stretch
      el-button(type="primary", @click="first", id="first-reserve-btn") 初次預約
    .op.flex.column.align-stretch.flex-1
      el-button(type="primary", @click="createAppointment()", id="member-reserve-btn") 選擇會員並新增預約
    el-button(@click="close(false)") 取消
  quick-person-creator(
    ref="quickPersonCreator", :append-to-body="true", @finish="chooseUser")
  appointment-creator(
    ref="appointmentCreator", :append-to-body="true"
    @finish="afterCreate")
</template>

<script>
import QuickPersonCreate from '@/components/drawers/QuickPersonCreate.vue';
import AppointmentCreate from '@/components/drawers/AppointmentCreate.vue';

export default {
  name: 'appointment-new',
  components: {
    'quick-person-creator': QuickPersonCreate,
    'appointment-creator': AppointmentCreate,
  },
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
    },
    first() {
      this.$refs.quickPersonCreator.$emit('show');
    },
    createAppointment(user) {
      this.$refs.appointmentCreator.$emit('show', {
        user,
      });
    },
    chooseUser(user) {
      this.createAppointment(user);
    },
    afterCreate(result) {
      if (result) {
        this.close(true);
      }
    },
  },
  mounted() {
    this.$on('show', () => {
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
  height: 100%;
}
</style>
