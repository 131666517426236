import { render, staticRenderFns } from "./EducationSelect.vue?vue&type=template&id=5cb582a6&scoped=true&lang=pug"
import script from "./EducationSelect.vue?vue&type=script&lang=js"
export * from "./EducationSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb582a6",
  null
  
)

export default component.exports