<template lang="pug">
.user-viewer.flex.column.align-stretch
  .header.flex.align-center.gap-20
    el-button.button(@click="$emit('close')", icon="el-icon-back", circle, size="mini")
    .title.flex.align-center.gap-10
      .name {{data.family_name}}{{data.given_name}}
      span 個案資料
      el-button(type="primary" plain @click="showDetail") 查看詳細資料
      el-button(v-if="showCopy", plain @click="copyURL") 複製個案網址
      el-button(plain, @click="createForm") 個案 QRcode
  .content-page.flex.column.gap-40.overflow-overlay
    .part.flex.column.align-start.gap-10
      .title 個案狀態
      el-select.status-selector(
        @change="updateStatus"
        v-model="showStatus")
        el-option(label="未進案", value="notstart")
        el-option(label="初進案", value="first_created")
        el-option(label="重新進案", value="recreated")
        el-option(label="持續唔談", value="in_progress")
        el-option(label="持續請假中", value="continue_requesting_off")
        el-option(label="結案", value="closed")
      .desc.flex.align-center.gap-6
        span 最後更新時間 {{ statusLastUpdateTime }}
        span(v-if="statusLastUpdateBy !== ''") ({{statusLastUpdateBy}} 更新)
    .part.flex.column.align-stretch.gap-10
      .title 預約記錄
      .filter.flex.column.gap-6
        .col 預約時間
        .flex.align-center.justify-space-between
          el-date-picker.date-range-input(
            @change="changeDate"
            v-model="times"
            type="daterange"
            range-separator="至")
          el-checkbox(v-model="hideCancel") 隱藏已取消的預約
      .table.hack-table
        el-table(
          @expand-change="toggleRow"
          :data="showedReserveList", style="width: 100%")
          el-table-column(type="expand", width="56")
            template(slot-scope="scope")
              .columns.flex.column.gap-10
                .col.flex.column
                  .text-secondary 本次預約備註
                  .value {{ detailMap[scope.row.id]?.note || '--' }}
                .col.flex.column
                  .text-secondary 通訊諮商所在地點
                  .value {{ scope.row.happen_place || scope.row.user_location || '--' }}
                .col.flex.column(v-if="detailMap[scope.row.id]?.cancel")
                  .text-secondary 取消原因
                  .value {{ detailMap[scope.row.id]?.cancel || '' }}
          el-table-column(label="預約時間與時長")
            template(slot-scope="scope")
              .flex.align-center.gap-6(:class="{'text-cancel': scope.row.isCancelled}")
                .time {{ scope.row.startTime }}
                .length ({{scope.row.length}} hr)
          el-table-column(label="專案 / 使用次數")
            template(slot-scope="scope")
              .flex.align-center.gap-8(
                :class="{'text-cancel': scope.row.isCancelled}")
                .project.flex-1.flex.column
                  .organization-name {{ scope.row.organization?.name || '' }}
                  .project-name {{ scope.row.project?.name || '' }}
                template(v-if="scope.row.user_role !== 'partner' && scope.row.project")
                  .times {{ scope.row.project.used }}/{{ scope.row.project.total }}
                template(v-else)
                  .relation {{ $msg(`appointment.type.${scope.row.appointment_type}`) }}
          el-table-column(label="標記")
            template(slot-scope="scope")
              .tags.flex.align-center.gap-4(:class="{'text-cancel': scope.row.isCancelled}")
                el-tag(v-for="n in scope.row.tags", :key="n") {{ n }}
                el-select.selector-no-text(
                  multiple, v-model="scope.row.tags", @change="updateBranchTags(scope.row)")
                  el-option(
                    v-for="tag in tags",
                    v-if="tag.public",
                    :key="tag.name", :label="tag.name", :value="tag.name")
          el-table-column(label="專業人士")
            template(slot="header", slot-scope="scope")
              .flex.align-center
                template(v-if="proSearch")
                  pro-picker(
                    size="mini"
                    ref="proPicker"
                    @clear="setProFilter(undefined)"
                    @input="setProFilter")
                template(v-else)
                  span 專業人士
                  i.el-icon-search.clickable(@click="editProSearch")
            template(slot-scope="scope")
              pro-badge(
                :class="{'text-cancel': scope.row.isCancelled}"
                :professional="scope.row.professional")
          el-table-column(label="個案記錄")
            template(slot-scope="scope")
              span.cancel-text(v-if="scope.row.isCancelled") 個案已取消
              span(v-else-if="scope.row.has_record")
                .download-list.flex.column.align-start(v-if="canDownloadResult")
                  a.record-link.link-text.flex.align-center.gap-4(target="_blank", :href="scope.row.record.url")
                    img(:src="successLogo")
                    span 下載紀錄
                  template(v-for="(attachment, idx) in scope.row.attachments")
                    a.record-link.link-text.flex.align-center.gap-4(target="_blank", :href="attachment.url")
                      span 附件{{idx+1}}: {{ attachment.filename }}
              span(v-else :class="{'text-cancel': scope.row.isCancelled}") ---
      el-pagination(
        layout="total, sizes, prev, pager, next, ->, jumper"
        :page-size.sync="limit"
        :current-page.sync="currentPage"
        :total="showedTotal"
      )
    .part.flex.align-stretch.gap-40
      .left.flex.column
        .part.flex.column.align-stretch.gap-10
          .flex.align-center.gap-20
            .title 個案備註
            template(v-if="editNote")
              .flex.align-center
                el-button(
                  type="primary", size="mini", round
                  @click="saveNote") 儲存
                el-button(
                  size="mini", round
                  @click="editNote = false") 取消
            template(v-else)
              el-button(
                type="primary", size="mini", round,
                @click="startEditNote") 編輯
              .flex-1
              .update-time.flex.gap-6
                span 最後更新時間
                span {{ noteLastUpdate }}
                span(v-if="noteLastUpdate !== '未修改'") ({{ noteLastUpdateBy }} 修改)
          .user-note.overflow-overlay
            template(v-if="editNote")
              el-input(
                type="textarea"
                v-model="noteInput"
                :autosize="true"
                placeholder="請輸入個案備註"
              )
            template(v-else)
              pre.note {{ data.note?.text }}
      .right.flex.column.gap-40
        .part.flex.column.align-stretch.gap-10
          .title 其他設定
          .line-status.flex.align-center.gap-10
            .flex.align-center.gap-4
              span LINE 綁定狀態：
              span(v-if="lineToken !== ''") 已綁定
              span(v-else) 未綁定
              line-chat-id-edit-button(:id="chatID", :uid="uid")
          .pay-status.flex.align-center.gap-10
            span 線上支付功能
            toggle(
              active-text="開啟"
              inactive-text="關閉"
              v-model="data.online_payable"
            )
        .part.flex.column.align-start.gap-10
          .title 檔案留存
          el-button(type="primary", round, @click="upload") 上傳檔案
          input(
            type="file", ref="upload",
            @input="changeFile")
          .flex.align-center.gap-6(v-for="attach in attachments")
            .filename.link-text.clickable(@click="$goLink(attach.url)") {{ attach.filename }}
            .delete-icon.danger-color.clickable
              el-popconfirm(title="是否確認要刪除", @confirm="deleteAttach(attach)")
                i.el-icon-circle-close(slot="reference")
  person-view-drawer(
    ref="personViewDrawer",
    :hideDetail="true"
    :append-to-body="true")
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getUser,
  getUserAppointments,
  updateUser,
  updateUserStatus,
  updateUserNote,
  getUserAttachments,
  updateUserAttachments,
  deleteAttach,
  createUserForm,
} from '@/api/user';
import { getTags } from '@/api/tags';
import { getOrganizations } from '@/api/project';
import { updateAppointmentTags, getAppointment } from '@/api/reserve';
import PersonViewer from '@/components/drawers/PersonViewer.vue';
import constant from '@/util/constant';
import successLogo from '@/assets/logo/success.svg';

export default {
  components: {
    'person-view-drawer': PersonViewer,
  },
  props: {
    uid: {
      type: [String, Number],
      default: '',
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      times: [],
      appointments: [],
      attachments: [],
      noteInput: '',
      noteLastUpdate: '',
      noteLastUpdateBy: '',
      editNote: false,
      showStatus: 'first_created',
      statusLastUpdateTime: '',
      statusLastUpdateBy: '',

      limit: 100,
      currentPage: 1,
      total: 0,
      payment: true,

      detailMap: {},
      projectOrgMap: {},

      proSearch: false,
      proFilter: undefined,

      lineToken: '',
      chatID: '',
      hideCancel: false,
      successLogo,
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'isAdvStaff', 'userDisplayName']),
    canDownloadResult() {
      return this.isAdmin || this.isAdvStaff;
    },
    filteredList() {
      return this.appointments.filter((r) => {
        if (this.proFilter && r.professional.id !== this.proFilter) {
          return false;
        }
        if (this.hideCancel && r.status === 'cancelled') {
          return false;
        }
        return true;
      });
    },
    showedReserveList() {
      const startIdx = (this.currentPage - 1) * this.limit;
      const endIdx = this.currentPage * this.limit;
      return this.filteredList.slice(startIdx, endIdx);
    },
    showedTotal() {
      return this.filteredList.length;
    },
  },
  watch: {
    uid() {
      if (this.uid !== '') {
        this.loadData();
      }
    },
  },
  methods: {
    openChat() {
      this.$goLink(`${constant.lineChatURL}/${this.chatID}`);
    },
    async showEditChatID() {
      let chatID = '';
      if (this.chatID) {
        chatID = this.chatID;
      }
      try {
        console.log(this.$prompt);
        const result = await this.$prompt('請輸入 LINE Chat ID', '', {
          confirmButtonText: '儲存',
          cancelButtonText: '取消',
          inputValue: chatID,
        });
        chatID = result.value;
      } catch (e) {
        console.log('cancel');
      }

      console.log(chatID);
      if (!chatID) {
        this.$showError('請輸入有效的 ChatID');
      }
      this.chatID = chatID;
      let origID = '';
      return this.$execWithLoading(async () => {
        const data = await getUser(this.uid);
        origID = data.line_chat_id;
        data.line_chat_id = chatID;
        await updateUser(this.uid, this.generateUserFromOrigData(data));
        this.$showSuccess('設定成功');
      }, (e) => {
        this.chatID = origID;
        this.$showAxiosException('更新失敗', e);
      });
    },
    async createForm() {
      const url = await createUserForm(this.uid);
      window.open(`/form-link.html?link=${encodeURIComponent(url)}&name=${this.data.family_name}${this.data.given_name}`);
    },
    async getOrganizations() {
      const rsp = await getOrganizations();
      this.projectOrgMap = {};
      rsp.forEach((org) => {
        org.projects.forEach((p) => {
          this.projectOrgMap[p.id] = org;
        });
        // eslint-disable-next-line no-param-reassign
        org.projects = org.projects.filter((p) => p.public);
      });
    },
    generateUserFromOrigData(data) {
      return {
        family_name: data.family_name,
        given_name: data.given_name,
        gender: data.gender,
        birthday: data.birthday,
        id_number: data.id_number,
        passport: data.passport,
        phone: data.phone,
        email: data.email,
        country: data.country,
        county: data.county,
        district: data.district,
        address: data.address,
        relationship: data.relationship,
        education: data.education,
        industry: data.industry,
        job: data.job,
        emergency_person: data.emergency_person,
        emergency_relationship: data.emergency_relationship,
        emergency_contact: data.emergency_contact,
        prefer_language: data.prefer_language,
        refer_source: data.refer_source,
        therapy_history: data.therapy_history,
        suicide_record: data.suicide_record,
        wanna_be_called: data.wanna_be_called,
        line_chat_id: data.line_chat_id,
      };
    },
    setProFilter(p) {
      if (p === undefined) {
        this.proSearch = false;
        this.proFilter = undefined;
        return;
      }
      this.proFilter = p?.id;
      this.currentPage = 1;
    },
    editProSearch() {
      this.proSearch = true;
      this.$nextTick(() => {
        this.$refs.proPicker.$emit('focus');
      });
    },
    copyURL() {
      const url = `${document.location.origin}${document.location.pathname}/#/user/${this.uid}`;
      this.$copy(url);
      this.$showSuccess('已複製網址');
    },
    upload() {
      this.$refs.upload.click();
    },
    async changeFile() {
      if (this.$refs.upload.files.length < 1) {
        return;
      }
      this.$execWithLoading(async () => {
        const file = this.$refs.upload.files[0];
        const rsp = await updateUserAttachments(this.uid, file);
        console.log(file);
        console.log(rsp);
        this.$showSuccess('上傳完成');
        await this.loadAttachments();
      }, (e) => {
        this.$showAxiosException(e);
      });
    },
    getDefaultTime() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const start = new Date(now.getTime() - 30 * 86400 * 1000);
      const end = new Date(now.getTime() + 30 * 86400 * 1000);
      end.setHours(0, 0, 0, 0);
      return [start, end];
    },
    changeDate() {
      this.loadData();
    },
    loadData() {
      if (!this.times || this.times.length === 0) {
        this.times = this.getDefaultTime();
      }
      const [start, end] = this.times;
      end.setHours(23, 59, 59, 999);

      const startTime = parseInt(start.getTime() / 1000, 10);
      const endTime = parseInt(end.getTime() / 1000, 10);

      setTimeout(() => {
        this.$execWithLoading(async () => {
          await Promise.all([
            this.loadTags(),
            this.getOrganizations(),
          ]);
          const data = await getUser(this.uid);
          this.data = data;
          this.noteInput = data.note?.text || data.note || '';
          this.noteLastUpdate = this.$timestampToDateTime(data.note?.last_updated) || '未修改';
          const by = data.note?.last_updated_by;
          this.noteLastUpdateBy = `${by?.family_name}${by?.given_name}`;
          this.showStatus = data.status?.state || 'first_created';
          if (this.showStatus === 'created') {
            this.showStatus = 'first_created';
          }
          this.statusLastUpdateTime = this.$timestampToDateTime(data.status?.last_updated);
          const statusBy = data.status?.last_updated_by;
          this.statusLastUpdateBy = `${statusBy?.family_name || ''}${statusBy?.given_name || ''}`;
          this.lineToken = data.lineToken || data.line_token || '';
          this.chatID = data.lineChatID || data.line_chat_id || '';

          const tagMap = this.tags.reduce((ret, t) => {
          // eslint-disable-next-line no-param-reassign
            ret[t.name] = t.public;
            return ret;
          }, {});

          const now = new Date();
          const nowTs = parseInt(now.getTime() / 1000, 10);
          const appointments = await getUserAppointments(this.uid, startTime, endTime);
          this.appointments = appointments.map((a) => ({
            ...a,
            tags: a.tags.filter((t) => tagMap[t] || a.start_at < nowTs),
            inFuture: a.start_at > nowTs,
            startTime: this.$timestampToDateTimeMinute(a.start_at),
            length: ((a.end_at - a.start_at) / 3600).toFixed(2),
            isCancelled: a.status === 'cancelled',
            organization: this.projectOrgMap[a.project?.id] || {},
            has_record: a.record !== null,
          }));
          await this.loadAttachments();
        });
      }, 100);
    },
    async loadTags() {
      const tags = await getTags();
      this.tags = tags;
    },
    async updateBranchTags(branch) {
      return this.$execWithLoading(async () => {
        await updateAppointmentTags(branch.id, branch.tags);
        this.$showSuccess('已更新標記');
      }, () => {
        this.$showError('更新標記失敗');
        this.loadData();
      });
    },
    async loadAttachments() {
      this.attachments = await getUserAttachments(this.uid);
    },
    showDetail() {
      this.$refs.personViewDrawer.$emit('show', {
        type: 'id',
        data: this.data.id,
      });
    },
    startEditNote() {
      this.noteInput = this.data.note?.text || '';
      this.editNote = true;
    },
    async saveNote() {
      this.$execWithLoading(async () => {
        await updateUserNote(this.data.id, this.noteInput);
        this.$showSuccess('更新成功');
        this.data.note = {
          text: this.noteInput,
          last_updated: new Date().getTime() / 1000,
        };
        this.editNote = false;
        this.noteLastUpdate = this.$timestampToDateTime(new Date());
        this.noteLastUpdateBy = this.userDisplayName;
      }, () => {
        this.$showError('更新失敗，請稍後再試');
        this.noteInput = this.data.note || '';
      });
    },
    async updateStatus() {
      this.$execWithLoading(async () => {
        await updateUserStatus(this.data.id, this.showStatus);
        this.data.status = {
          state: this.showStatus,
          last_updated: new Date().getTime() / 1000,
        };
        this.statusLastUpdateTime = this.$timestampToDateTime(new Date());
        this.statusLastUpdateBy = this.userDisplayName;
        this.$showSuccess('更新成功');
      }, () => {
        this.$showError('更新失敗，請稍後再試');
        this.showStatus = this.data.status?.state || 'first_created';
      });
    },
    toggleRow(row) {
      console.log({ row });
      this.loadAppointment(row.id);
    },
    async loadAppointment(id) {
      if (this.detailMap[id]) {
        return true;
      }
      return this.$execWithLoading(async () => {
        const data = await getAppointment(id);
        this.detailMap[id] = data;
        this.$forceUpdate();
      });
    },
    deleteAttach(attach) {
      console.log(attach);
      this.$execWithLoading(async () => {
        await deleteAttach(attach.url);
        await this.loadAttachments();
      });
    },
  },
  mounted() {
    if (this.uid !== '') {
      this.loadData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.user-viewer {
  height: calc(100vh - #{$header-height});
  height: calc(100dvh - #{$header-height});
  width: 100vw;
  background: #F9F9F9;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .header {
    padding: 30px;
    padding-bottom: 0;
  }
  .content-page {
    padding: 30px;
  }
  .button {
    color: black;
  }
  .part {
    .title {
      font-weight: bold;
      color: $text-primary-color;
      line-height: 28px;
    }
    .desc {
      color: $text-secondary-color;
    }
    .left {
      flex: 2 0 850px;
    }
    .right {
      flex: 1 0 400px;
    }
    .user-note {
      background: white;
      height: 300px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
        0px 2px 6px rgba(0, 0, 0, 0.06),
        0px 4px 8px 2px rgba(0, 0, 0, 0.04);
      .note {
        margin: 10px;
      }
    }
    input[type=file] {
      display: none;
    }
  }
  .status-selector {
    width: 440px;
  }
  .table {
    max-height: 600px;
    .columns {
      padding: 0 56px;
    }
    .project {
      .organization-name {
        color: #414345;
      }
      .project-name {
        color: #909399;
      }
    }
  }
}
</style>

<style lang="scss">
.part {
  .user-note {
    textarea {
      border: none;
    }
  }
}
</style>
