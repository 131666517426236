<template lang="pug">
el-drawer(
  title="建立帳號"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.gap-20.default
    .data.flex.align-stretch.gap-6
      .flex.column.align-stretch.gap-6.flex-1
        .title 個案姓氏
        el-input.input(
          name="familyName"
          @input="loadExisted"
          v-model="familyName", placeholder="請輸入姓氏")
      .flex.column.align-stretch.gap-6.flex-1
        .title 個案名字
        el-input.input(
          name="givenName"
          @input="loadExisted"
          v-model="givenName", placeholder="請輸入名字")
    .data.flex.align-center.justify-stretch.gap-10
      .left.flex.column.align-stretch.gap-6
        .title 國碼
        el-select(v-model="phoneCountry" filterable)
          el-option(
            v-for="country in countryList"
            :key="country.code"
            :value="country.dial_code"
            :label="`${country.code} ${country.dial_code}`"
          )
      .right.flex.column.align-stretch.gap-6
        .title 聯絡電話
        el-input(name="phone", @input="loadPhoneExisted", v-model.trim="phone")
    .data.flex.column.align-stretch.gap-6
      .title Email*
      el-input.input(
        name="email"
        v-model="email", placeholder="請輸入 Email")
    .op.flex.align-center-justify-stretch.gap-10
      el-button.button(@click="cancel") 取消
      el-button.button(@click="save", type="primary", :disabled="!valid", id="quick-add-btn") 建立帳號
    .splitter
    .existed-users.flex.column.gap-20
      .desc.flex.align-center
        div 已存在的帳號
        div(v-if="loading") ：讀取中
        div(v-else-if="existedUser.length === 0") ：無
      .existed-user.flex.align-center(v-for="u in existedUser")
        .left.flex.column.flex-1
          .flex.align-center.gap-6
            .name {{`${u.family_name}${u.given_name}`}}
            span /
            .phone {{ u.phone }}
        .right.flex-auto
          el-button(size="mini", @click="choose(u)") 為此個案新增預約
</template>

<script>
import countryList from '@/util/country';
import { getUsers, createUser } from '@/api/user';

export default {
  name: 'quick-person-creator',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countryList,
      drawer: false,
      familyName: '',
      givenName: '',
      phoneCountry: '+886',
      phone: '',
      email: '',

      loading: false,
      existedUser: [],
      waitToSearch: undefined,
    };
  },
  computed: {
    valid() {
      if (this.loading) {
        return false;
      }
      if (this.givenName === '' && this.familyName === '') {
        return false;
      }
      return true;
    },
  },
  methods: {
    handleClose(done) {
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
    },
    async loadPhoneExisted() {
      if (this.loading) {
        this.waitToSearch = this.phone;
        return;
      }
      this.loadKeywordUser(this.phone);
    },
    async loadExisted() {
      const keyword = `${this.familyName}${this.givenName}`;
      if (this.loading) {
        this.waitToSearch = keyword;
        return;
      }
      this.loadKeywordUser(keyword);
    },
    async loadKeywordUser(keyword) {
      if (keyword !== '') {
        try {
          this.loading = true;
          const rsp = await getUsers(0, 100, keyword);
          this.existedUser = rsp.data.slice(0, 10);
        } catch (e) {
          console.log(e);
        }
        if (this.waitToSearch !== undefined) {
          const nextKeyword = this.waitToSearch;
          this.waitToSearch = undefined;
          this.loadKeywordUser(nextKeyword);
          return;
        }
      } else {
        this.existedUser = [];
      }
      this.loading = false;
    },
    choose(u) {
      this.close(u);
    },
    async save() {
      this.$execWithLoading(async () => {
        const user = {
          family_name: this.familyName,
          given_name: this.givenName,
          phone: this.$getPhone(this.phoneCountry, this.phone),
          email: this.email,
        };
        const rsp = await createUser(user);
        this.close({
          id: rsp.id,
          ...user,
        });
      }, (e) => {
        console.log(e);
        this.$showAxiosException('建立帳號失敗', e);
      });
    },
    reset() {
      this.familyName = '';
      this.givenName = '';
      this.phoneCountry = '+886';
      this.phone = '';
      this.email = '';

      this.loading = false;
      this.existedUser = [];
      this.waitToSearch = undefined;
    },
    cancel() {
      this.close();
    },
  },
  mounted() {
    this.$on('show', () => {
      this.reset();
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.drawer {
  padding: 0 20px;
  padding-bottom: 20px;
  .data {
    .left {
      flex: 0 0 120px;
    }
    .right {
      flex: 1;
    }
  }
  .button {
    flex: 1;
  }
  .splitter {
    border: 1px solid $border-base-color;
  }
  .existed-user {
    background: #F5F7FA;
    border: 1px solid $border-base-color;
    border-radius: 4px;
    padding: 10px 20px 10px 10px;
    flex: 0 1 68px;
  }
}
</style>
