<template lang="pug">
.professional-picker.flex.align-center.justify-stretch
  el-autocomplete.flex-1(
    ref="input"
    clearable
    v-model="name"
    :fetch-suggestions="querySearch"
    placeholder="請輸入姓名",
    @blur="$emit('blur')"
    @select="handleSelect"
    @input="changeUser"
    @clear="$emit('clear')"
    :trigger-on-focus="false"
    :size="size"
  )
</template>

<script>
import { getProfessionalList } from '@/api/professionals';

export default {
  props: {
    size: {
      type: String,
      default: 'normal',
    },
    showHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      options: undefined,
    };
  },
  methods: {
    handleSelect(professional) {
      this.$emit('input', professional);
    },
    querySearch(query, cb) {
      this.loadKeywordProfessional(query).then((data) => {
        cb(data.filter((d) => d.id !== this.exclude).map((d) => ({
          ...d,
          value: `${d.family_name}${d.given_name}`,
        })));
      });
    },
    async loadKeywordProfessional(keyword) {
      if (this.options === undefined) {
        const data = await getProfessionalList();
        this.options = data;
      }
      return this.options.filter(
        (opt) => (this.showHide || opt.public)
        && (opt.family_name.indexOf(keyword) >= 0
        || opt.given_name.indexOf(keyword) >= 0
        || (`${opt.family_name}${opt.given_name}`).indexOf(keyword) >= 0),
      );
    },
    changeUser(val) {
      if (val === '') {
        this.handleSelect(undefined);
      }
    },
  },
  mounted() {
    this.$on('focus', () => {
      this.$refs.input.focus();
    });
    this.$on('name', (data) => {
      this.name = data;
    });
    this.$on('reset', () => {
      this.name = '';
    });
  },
};
</script>

<style lang="scss" scoped>

</style>
