/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import {
  timestampToDateTime,
  timestampToDate,
  timestampToDateWithDay,
  timestampToDateTimeMinute,
  timestampToDateTimeMinuteWithDay,
  timestampToTime,
  timestampToTimeM,
  timestampToM,
  toCurrencyNumber,
} from '@/util/format';
import { getList } from 'country-list-with-dial-code-and-flag';

const countries = getList();
countries.sort((a, b) => (a.name > b.name ? 1 : -1));
const idx = countries.findIndex((c) => c.dial_code === '+886');
const tw = countries[idx];
tw.name = 'Taiwan';
countries.splice(idx, 1);
countries.unshift(tw);

function isPromise(p) {
  if (typeof p === 'object' && typeof p.then === 'function') {
    return true;
  }

  if (p.constructor.name === 'AsyncFunction') {
    return true;
  }

  return false;
}

export default {
  install(Vue) {
    Vue.prototype.$isPromise = isPromise;
    Vue.prototype.$splitPhone = (input) => {
      let country = '+886';
      let phone = '';
      if (input === 'None' || input === undefined || input === null) {
        return [country, phone];
      }
      for (let i = 0; i < countries.length; i += 1) {
        if (input.indexOf(countries[i].dial_code) === 0) {
          country = countries[i].dial_code;
        }
      }

      if (input === null) {
        return [country, phone];
      }
      if (input.indexOf(' ') > 0) {
        [country, phone] = input.split(' ');
      } else if (input !== 'None') {
        phone = `${country === '+886' ? '0' : ''}${input.replace(country, '')}`;
      }
      return [country, phone];
    };
    Vue.prototype.$getShowPhone = (input) => {
      if (input === 'None' || input === undefined || input === null) {
        return '';
      }
      let country = '';
      let phone = '';
      [country, phone] = Vue.prototype.$splitPhone(input);
      if (country === '+886' && phone.indexOf('0') === 0) {
        phone = phone.substring(1);
      }
      if (phone === '') {
        return '';
      }
      return `${country}-${phone}`;
    };
    Vue.prototype.$getPhone = (country, phone) => {
      if (phone === '') {
        return '';
      }
      return `${country} ${phone}`;
    };
    Vue.prototype.$execWithLoading = async function (func, errFunc) {
      return this.$execWithLoadingMessage('Loading', func, errFunc);
    };
    Vue.prototype.$execWithLoadingMessage = async function (msg, func, errFunc) {
      const loading = this.$loading({
        lock: true,
        text: msg,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let promise = new Promise((r) => {
        r();
      });

      return new Promise((r) => {
        setTimeout(async () => {
          promise = promise.then(() => func.apply(this));
          try {
            await promise;
          } catch (e) {
            console.log(`loading exception: ${e}`);
            console.trace();
            if (errFunc !== undefined) {
              try {
                errFunc(e);
              } catch (e2) {
                console.log(e2);
              }
            }
          }
          loading.close();
          r();
        }, 10);
      });
    };
    Vue.prototype.$defaultLoading = function () {
      return this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    };
    Vue.prototype.$dateToTimestamp = (d) => {
      const result = parseInt(
        (new Date(d)).getTime() / 1000,
        10,
      );
      if (Number.isNaN(result)) {
        return undefined;
      }
      return result;
    };
    Vue.prototype.$timestampToDateTime = (input, dft) => timestampToDateTime(input, dft);
    Vue.prototype.$timestampToDateTimeMinute = (
      input, dft,
    ) => timestampToDateTimeMinute(input, dft);
    Vue.prototype.$timestampToDateTimeMinuteWithDay = (
      input, dft,
    ) => timestampToDateTimeMinuteWithDay(input, dft);
    Vue.prototype.$timestampToDate = (input) => timestampToDate(input);
    Vue.prototype.$timestampToDateWithDay = (input) => timestampToDateWithDay(input);
    Vue.prototype.$timestampToTime = (input) => timestampToTime(input);
    Vue.prototype.$timestampToTimeM = (input) => timestampToTimeM(input);
    Vue.prototype.$timestampToM = (input) => timestampToM(input);
    Vue.prototype.$toCurrencyNumber = (input) => toCurrencyNumber(input);
    Vue.prototype.$showSuccess = function (title) {
      this.$notify.success({
        title,
      });
    };
    Vue.prototype.$showError = function (title) {
      this.$notify.error({
        title,
      });
    };
    Vue.prototype.$showWarn = function (title, message) {
      this.$notify({
        title,
        message,
        type: 'warning',
      });
    };
    Vue.prototype.$showAxiosException = function (title, e) {
      if (e.response?.status === 401) {
        this.$showError(window.msg.errorSessionExpire);
        this.$router.push('/login');
        return;
      }
      const errKey = e.response?.data?.error || 'unknown';
      setTimeout(() => {
        this.$showError(`${title}: ${this.$tryMsg(`axiosErr.${errKey}`)}`);
      }, 10);
    };
    Vue.prototype.$addUILog = function (args) {
      const debug = document.querySelector('#debug');
      if (debug) {
        const orig = debug.textContent;
        debug.textContent = `${orig}\n\n${args.map((a) => {
          if ((typeof a).toLowerCase() === 'object') {
            return JSON.stringify(a);
          }
          return a.toString();
        }).join('\n')}`;
      }
    };
    Vue.prototype.$waitMs = async function (ms) {
      return new Promise((r) => {
        setTimeout(() => {
          r();
        }, ms);
      });
    };
    Vue.prototype.$goLink = function (url) {
      if (url) {
        window.open(url, '_blank');
      }
    };

    Vue.prototype.$copy = function (text, notify) {
      const input = document.createElement('input');
      input.value = text;
      const body = document.querySelector('body');
      body.append(input);

      input.select();
      document.execCommand('copy');
      input.remove();

      if (notify) {
        this.$showSuccess(`已經成功複製 ${text}`);
      }
    };

    Vue.prototype.$showConfirm = function ({
      title, content, confirmButtonText, cancelButtonText, confirmType, customClass,
    }) {
      return new Promise((resolve, reject) => {
        this.$confirm(content, title, {
          confirmButtonText,
          cancelButtonText,
          type: confirmType,
          customClass,
        }).then(async () => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
    };
    Vue.prototype.$getVersion = async function () {
      let beVersion = '-';
      let beUpdateTime = '-';
      let version = '-';
      let updateTime = '-';
      try {
        const response = await fetch('/backend-version.json');
        const data = await response.json();
        beVersion = data.commit.slice(0, 8);
        beUpdateTime = this.$timestampToDateTimeMinute(data.updated_at);
      } catch (e) {
        console.error(e);
      }
      if (window.version) {
        version = window.version;
        updateTime = window.updated;
      }
      return `Version: ${version}, Updated: ${updateTime}, BEVersion: ${beVersion}, BEUpdated: ${beUpdateTime}`;
    };
  },
};
