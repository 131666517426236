import instance from './base';

export async function login(username, password) {
  const rsp = await instance.post('/v1/login', { username, password });
  return rsp.data;
}

export async function logout() {
  const rsp = await instance.post('/v1/logout');
  return rsp.data;
}

export async function verifyOTP(code) {
  const rsp = await instance.post('/v1/totp/verify', { code });
  return rsp.data;
}

export async function getProfile() {
  const rsp = await instance.get('/v1/profile');
  return rsp.data;
}

export async function getProfileWithKey(key) {
  const rsp = await instance.get(`/v1/profile_by_key?key=${key}`);
  return rsp.data;
}

export async function createOTP() {
  const rsp = await instance.post('/v1/totp');
  return rsp.data;
}

export async function forgetPassword(email) {
  const rsp = await instance.post('/v1/password/reset', {
    email,
  });
  return rsp.data;
}

export async function updatePasswordByToken(token, password) {
  const rsp = await instance.put('/v1/password', {
    token, password,
  });
  return rsp.data;
}

export async function appUpdatePasswordByToken(token, password) {
  const rsp = await instance.put('/v1/password/reset/handle', {
    token, password,
  });
  return rsp.data;
}

export async function appUpdatePassword(oldPassword, newPassword) {
  const rsp = await instance.put('/v1/password', {
    old_password: oldPassword,
    new_password: newPassword,
  });
  return rsp.data;
}

export async function updateProfile(origPwd, password) {
  const rsp = await instance.put('/v1/profile', {
    origPwd,
    password,
  });
  return rsp.data;
}

export async function appUpdateProfile(profile) {
  const rsp = await instance.put('/v1/profile', profile);
  return rsp.data;
}

export async function updateSelfName(name) {
  const rsp = await instance.put('/v1/profile/names', {
    family_name: name[0],
    given_name: name.substr(1),
  });
  return rsp.data;
}

export async function updateSelfPassword(oldPassword, newPassword) {
  const rsp = await instance.put('/v1/profile/password', {
    old_password: oldPassword,
    new_password: newPassword,
  });
  return rsp.data;
}
