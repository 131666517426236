<template lang="pug">
.professional-picker.flex.align-center.justify-stretch
  el-select.flex-1(
    v-model="localValue",
    filterable, clearable,
    placeholder="請選擇專業人士", @change="handleSelect")
    el-option(v-if="showAll", label="全部", value="")
    el-option(v-for="opt in options", :key="opt.id", :label="opt.label", :value="opt.id")
</template>

<script>
import { getProfessionalList } from '@/api/professionals';

export default {
  name: 'pro-selector',
  props: {
    size: {
      type: String,
      default: 'normal',
    },
    value: {
      required: true,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    publicOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: undefined,
      options: undefined,
    };
  },
  watch: {
    value() {
      this.localValue = this.value;
    },
  },
  methods: {
    handleSelect(professional) {
      this.$emit('input', professional);
    },
    async loadData() {
      const data = await getProfessionalList();
      const options = data.map((p) => ({
        ...p,
        label: `${p.family_name}${p.given_name}`,
      })).filter((p) => !this.publicOnly || p.public);
      options.sort((a, b) => a.label.localeCompare(b.label, 'zh-Hant'));
      this.options = options;
    },
  },
  mounted() {
    this.loadData();
    if (this.showAll) {
      this.localValue = '';
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
