<template lang="pug">
el-select.relation(
  :disabled="disabled"
  clearable
  v-model="relation", placeholder="請選擇感情狀態")
  el-option(v-for="e in relationList"
    :key="e.value"
    :value="e.value"
    :label="e.text"
)
</template>

<script>
export default {
  name: 'relation-select',
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value() {
      if (this.relation !== this.value) {
        this.relation = this.value;
      }
    },
    relation() {
      if (this.relation !== this.value) {
        this.$emit('input', this.relation);
      }
    },
  },
  data() {
    return {
      relation: '',
      relationList: [
        { value: 'single', text: this.$msg('appointment.relationship.single') },
        { value: 'inarelationship', text: this.$msg('appointment.relationship.inarelationship') },
        { value: 'married', text: this.$msg('appointment.relationship.married') },
        { value: 'engaged', text: this.$msg('appointment.relationship.engaged') },
        { value: 'separate', text: this.$msg('appointment.relationship.separate') },
        { value: 'divorced', text: this.$msg('appointment.relationship.divorced') },
        { value: 'remarry', text: this.$msg('appointment.relationship.remarry') },
        { value: 'widowed', text: this.$msg('appointment.relationship.widowed') },
      ],
    };
  },
  mounted() {
    this.relation = this.value;
  },
};
</script>

  <style lang="scss" scoped>

  </style>
